<template>
  <b-card title="Page Not Found" class="m-2">
    <p>The requested page could not be found.</p>
    <b-button to="/" variant="primary">Home</b-button>
  </b-card>
</template>

<script>
//import API from "@/api";

export default {
  name: "NotFOund",
  data: () => ({}),
  beforeCreate() {},
  methods: {},
};
</script>